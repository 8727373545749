import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { IoIosArrowForward } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { Link, styled } from '@mui/material'
import LabelTitle from '../typo/LabelTitle'
import { getPartDetails } from './services/getPartDetailsApi'
import Loader from "../global/Loader";

const MoreDetailsButton = styled(Link)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '8px 0',
	gap: '8px',
	cursor: 'pointer'
})

const MoreDetailTypo = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected, theme }) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '12px',
		color: isSelected ? theme.palette.text.secondary : theme.palette.text.primary
	})
)

const MoreDetailArrow = styled(IoIosArrowForward, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected, theme }) => ({
		color: isSelected ? theme.palette.text.secondary : theme.palette.text.primary
	})
)

type PartDetailType = {
	attributeId: string,
	attributeName: string,
	comparable: string,
	dataType: string,
	displayable: string,
	facetable: string,
	sequence: string,
	value: string,
	valueId?: string,
}

type PartDetailsType = PartDetailType[]

type PartDetailDialogType = {
	partNumber: string,
	seriesName: string,
	isSelected?: boolean
}

export const PartDetailDialog: FC<PartDetailDialogType> = (
	{
		partNumber,
		seriesName,
		isSelected = false
	}
) => {
	const { t } = useTranslation()
	const [open, setOpen] = React.useState(false)
	const [partDetails, setPartDetails] = useState<PartDetailsType>([])
	const [partDetailsLoading, setPartDetailsLoading] = useState<boolean>(false)

	useEffect(() => {
		if (open) {
			setPartDetailsLoading(true)
			getPartDetails(JSON.stringify({
				part_number: partNumber
			}))
				.then(response => {
					setPartDetails(extractPartDetails(response))
				})
				.finally(() => setPartDetailsLoading(false))
		}
	}, [open])

	const extractPartDetails = (data: { [index: string]: any }) => {
		data = data[0] ?? data
		let finalArray: PartDetailsType = []
		let technical: { [index: string]: any } = data['technical']

		for (var k in technical) {
			let partDetailsArray: PartDetailsType = technical[k]['part']

			for (var p in partDetailsArray) {
				finalArray.push(partDetailsArray[p])
			}
		}

		return finalArray
	}

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<div>
			<MoreDetailsButton underline="none" onClick={handleClickOpen}>
				<MoreDetailTypo isSelected={isSelected}>{t('components.assemblyProgressionPanel.moreDetails')}</MoreDetailTypo>
				<MoreDetailArrow isSelected={isSelected}/>
			</MoreDetailsButton>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
			>
				<AppBar sx={{ position: 'relative', backgroundColor: '#FFD760', color: 'black' }}>
					<Toolbar>
						<LabelTitle padding="24px" sx={{ ml: 2, flex: 1 }}>
							{seriesName}
						</LabelTitle>
						<Button autoFocus
						        onClick={handleClose}
						        variant="contained"
						        sx={{ color: 'white', backgroundColor: 'black', padding: '10px 50px', textTransform: 'initial' }}
						>
							Close
						</Button>
					</Toolbar>
				</AppBar>
				<div style={{
					padding: 60
				}}>
					<LabelTitle padding="0" sx={{
						fontSize: 30,
						paddingBottom: '30px'
					}}>
						Technical Specifications
					</LabelTitle>
					{partDetailsLoading ? <Loader /> : (
						<div style={{
							height: 'calc(100vh - 200px)',
							display: 'flex',
							flexDirection: 'column',
							flexWrap: 'wrap'
						}}>
							{partDetails.map(p => {
								return (
									<div key={`${p.attributeId}-${p.sequence}-${p.value}`} style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexWrap: 'wrap',
										paddingRight: 30,
										paddingBottom: 10,
										width: '50%'
									}}>
										<LabelTitle padding="0">
											{p.attributeName}:
										</LabelTitle>
										<LabelTitle padding="0" sx={{
											fontFamily: 'Inter',
											fontWeight: 'normal'
										}}>
											{p.value}
										</LabelTitle>
									</div>
								)
							})}
						</div>
					)}
				</div>
			</Dialog>
		</div>
	)
}
