import LabelSubtitle from "../../../../../../typo/LabelSubtitle";
import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Stack, styled } from "@mui/material";
import { LOCAL_STORAGE_KEYS } from "../../../../../../../config/enums";
import {PricesContext} from "../../../../../../../utils/contextes/PricesContext";

const SpecificityContainer = styled(Stack)({
    width: '36%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
    gap: '6px'
})

const Row = styled(Stack)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: '12px'
})
const QuantityItem = () => {

    const { t } = useTranslation()
    const { hosePrice, fittingBPrice, fittingAPrice } = useContext(PricesContext)

    return (
        <>
            <LabelSubtitle>{t('components.assemblyProgressionPanel.quantity.title')}</LabelSubtitle>
            <SpecificityContainer>
                {
                    !!hosePrice && hosePrice > 0
                    &&  !!fittingAPrice && fittingAPrice > 0
                    &&  !!fittingBPrice && fittingBPrice > 0
                        ? (
                            <>
                                {localStorage.getItem(LOCAL_STORAGE_KEYS.QUANTITY) && (
                                    <Row>
                                        <LabelSubtitle color="#828282">
                                            {t('components.assemblyProgressionPanel.quantity.libelle')}
                                        </LabelSubtitle>
                                        <LabelSubtitle>{localStorage.getItem(LOCAL_STORAGE_KEYS.QUANTITY)}</LabelSubtitle>
                                    </Row>
                                )}
                                <Row>
                                    <LabelSubtitle color="#828282">
                                        {t('components.assemblyProgressionPanel.price.total')}
                                    </LabelSubtitle>
                                    <LabelSubtitle>{(localStorage.getItem(LOCAL_STORAGE_KEYS.TOTAL_PRICE) + "€")}</LabelSubtitle>
                                </Row>
                            </>
                        )
                        :
                        <>
                            <Row>
                                <LabelSubtitle style={{paddingLeft: "15px"}}>
                                    {t('components.assemblyProgressionPanel.price.none')}
                                </LabelSubtitle>
                            </Row>
                        </>

                }

            </SpecificityContainer>
        </>


    )
}

export default QuantityItem