import React, { FC } from 'react'
import { Modal, Box, styled, Stack, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

type ChangeSelectionModalProps = {
    open: boolean,
    handleClose: () => void,
    handleConfirm: () => void
}

const BoxSelectionModal = styled(Box)(
    ({theme}) => ({
        display: 'flex',
        padding: '24px',
        width: '728px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
        borderRadius: '12px',
        borderTop: '4px solid',
        borderColor: theme.palette.button.primary,
        backgroundColor: theme.palette.text.secondary,
        boxShadow: '0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)'
    })
)

const ContentContainer = styled(Stack)({
    display: 'flex',
    padding: '24px',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    borderBottom: '1px solid rgba(0, 0, 0, 0.10)'
})

const TitleTypo = styled(Typography)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
        textAlign: 'center',
        fontFamily: 'DIN 2014',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '32px',
        fontSize: '24px'
    })
)

const SubtitleTypo = styled(Typography)(
    ({ theme }) => ({
        color: '#262626',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22px'
    })
)

const ButtonsSection = styled(Stack)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch'
})

const CSButton = styled(Button)({
    display: 'flex',
    flex: '1 0 0'
})

const ChangeSelectionModal: FC<ChangeSelectionModalProps> = (
    {
        open,
        handleClose,
        handleConfirm
    }
) => {
    const { t } = useTranslation()
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                position: 'absolute',
                top: '40%',
                left: '40%'
            }}
        >
            <BoxSelectionModal>
                <ContentContainer>
                    <TitleTypo>{t('modal.changeSelection.title')}</TitleTypo>
                    <SubtitleTypo>{t('modal.changeSelection.subtitle')}</SubtitleTypo>
                </ContentContainer>
                <ButtonsSection>
                    <CSButton
                        variant="outlined"
                        onClick={() => handleClose()}
                    >
                        {t('modal.changeSelection.cancel')}
                    </CSButton>
                    <CSButton
                        variant="contained"
                        onClick={() => {
                            handleConfirm()
                            handleClose()
                        }}
                    >
                        {t('modal.changeSelection.confirm')}
                    </CSButton>
                </ButtonsSection>
            </BoxSelectionModal>
        </Modal>
    )
}

export default ChangeSelectionModal
