import React from 'react'
import { styled, Typography } from '@mui/material'

const ProductTitle = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'isSelected'
	}
)<{ isSelected: boolean }>(
	({ isSelected , theme}) => ({
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '18px',
		color: isSelected ? theme.palette.text.secondary : theme.palette.text.primary
	})
)

export default ProductTitle