import React, { FC } from 'react'
import { Stack } from '@mui/material'
import BasicButton from '../../../components/buttons/BasicButton'
import { useTranslation } from 'react-i18next'
import { HomeMessage, HomeTitle } from '../../../components/typo/HomeTypo'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { HOME_LINKS } from '../../selector/utils/constants'


type ActualityType = {
	title?: string|null,
	text?: string|null,
	labelButton?: string|null,
	linkButton?: string|null,

}
const Actuality: FC<ActualityType> = ({title, text, labelButton, linkButton}) => {
	const { t } = useTranslation()
	return (
		<Stack flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
			<Stack width="50%">
				<HomeTitle>
					{
						!!title ? title : t('screens.home.content.actuality.title')
					}
				</HomeTitle>
				<HomeMessage>
					{
						!!text ? text : t('screens.home.content.actuality.message')
					}
				</HomeMessage>
			</Stack>
			<Stack alignItems="center" justifyContent="center">
				<BasicButton onClick={() => window.open(!!linkButton ? linkButton : HOME_LINKS.ACTUALITY, '_blank')} variant="outlined" startIcon={<ArrowForwardIosIcon />}>{!!labelButton ? labelButton : t('screens.home.content.actuality.button')}</BasicButton>
			</Stack>
		</Stack>
	)
}

export default Actuality
