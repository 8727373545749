import { styled } from '@mui/material'

export const PageContainerBase = styled('section')<{ direction: any, alignement: string }>(
	({ direction, alignement }) => ({
		display: 'flex',
		flexDirection: direction,
		flexGrow: 1,
		backgroundColor: "#D3D3D3",
		alignItems: alignement,
		minHeight: window.innerHeight - 90
	})
)


