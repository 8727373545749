import { useTranslation } from "react-i18next";
import { useCurrentStepContext } from "../../utils/utils";
import { Stack, styled, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CHARACTER_LIMIT_ASSEMBLY_LENGTH } from "../../../../utils/constants";
import LabelSubtitle from "../../../../components/typo/LabelSubtitle";
import { LOCAL_STORAGE_KEYS } from "../../../../config/enums";

const QuantityField = styled(TextField)(
    ({ theme }) => ({
        '& .MuiInputBase-input': {
            backgroundColor: theme.palette.secondary.main
        },
        '& .MuiTextField-root': {
            backgroundColor: theme.palette.secondary.main
        }
    })
)

const Quantity = () => {
    const { t } = useTranslation()
    const { currentStep, updateCurrentStep } = useCurrentStepContext()
    const [quantity, setQuantity] = useState<number>(1)

    useEffect(() => {
        if (quantity) {
            updateCurrentStep({
                rank: currentStep.rank,
                subStepKey: currentStep.subStepKey,
                lastSubStep: currentStep.lastSubStep,
                firstSubStep: currentStep.firstSubStep,
                values: [
                    {
                        localStorageKey: LOCAL_STORAGE_KEYS.QUANTITY,
                        value: quantity
                    }
                ]
            })
        }
    }, [quantity])

    return (
        <Stack>
            <LabelSubtitle>{t('screens.selector.quantity.title')}</LabelSubtitle>
            <QuantityField
                type="number"
                inputProps={{
                    maxLength: CHARACTER_LIMIT_ASSEMBLY_LENGTH,
                    pattern: /^-?\d+(?:\.\d+)?$/g,
                    min: 1
                }}
                value={quantity}
                onChange={e => {
                    if (e.target.value.length <= CHARACTER_LIMIT_ASSEMBLY_LENGTH) {
                        setQuantity(parseInt(e.target.value))
                    }
                }}
            />
        </Stack>
    )
}

export default Quantity