import { createTheme } from '@mui/material/styles'

/**
 * Thème général de l'application
 */
export const parkerFoTheme = (jauneParker?: any, noirParker?: any, blancParker?: any) => {
	return createTheme({
		palette: {
			primary: {
				main: noirParker ?? '#000000'
			},
			secondary: {
				main: blancParker ?? '#FFFFFF'
			},
			recap: {
				main: '#064E3B'
			},
			info: {
				main: '#E4E4E4'
			},
			text: {
				primary: noirParker ?? '#000000',
				secondary: blancParker ?? '#FFFFFF'
			},
			navbar: {
				startGradient: noirParker ?? '#000000',
				endGradient: jauneParker ?? '#FFB91D'
			},
			button: {
				primary: jauneParker ?? '#F9BA00'
			},
			success: {
				main: '#10B981'
			},
			assemblyPanel: {
				main: '#F9F9F9',
				card: {
					active: blancParker ?? '#FFFFFF',
					disable: '#E4E4E4',
					item: {
						active: jauneParker ?? '#FFD760',
						disable: 'transparent'
					}
				}
			},
			selectionDone: {
				background: '#D1FAE5',
				check: '#10B981'
			}
		},
		typography: {
			button: {
				fontWeight: 600,
				fontStyle: 'normal'
			}
		}
	})
}

