import React, { FunctionComponent } from 'react'
import HoseItem from './hoseItem/HoseItem'
import { Stack, styled } from '@mui/material'
import CheckRadioButton from '../../../../../components/form/radio/check/CheckRadioButton'

const HoseBodySection = styled(Stack)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	alignSelf: 'stretch'
})

const HoseSelectionBody: FunctionComponent<HoseSelectionBodyType> = (
	{
		hoseSelection,
		setHoseSelection,
		hoses
	}
) => {
  return (
	  <HoseBodySection>
		  {hoses.map((hose: HoseType) => (
			  <CheckRadioButton
				  padding="24px"
				  key={hose.part_number}
				  value={hose.part_number}
				  itemSelected={hoseSelection}
				  setSelection={setHoseSelection}
				  height="128px"
				  cursor={'pointer'}
			  >
				  <HoseItem hose={hose} isSelected={hose.part_number === hoseSelection} price={hose.price}/>
			  </CheckRadioButton>
		  ))}
	  </HoseBodySection>
  )
}

export default HoseSelectionBody
