import React, { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, styled, Typography } from '@mui/material'
import RefreshButton from './RefreshButton'
import LabelTitle from '../typo/LabelTitle'
import { CurrentStepContext } from '../../utils/contextes/CurrentStepContext'
import CheckIcon from '@mui/icons-material/Check'

const indicatorSize = '36px'

const IndicatorContainer = styled(Stack, {
		shouldForwardProp: (prop: string) => prop !== 'backgroundColor' && prop !== 'border'
	}
)<{ backgroundColor: string | null, border: string, borderCol: string | null }>(
	({ backgroundColor, border, borderCol, theme }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4px',
		background: !!backgroundColor ? backgroundColor : theme.palette.text.primary,
		borderRadius: '50px',
		border: border,
		borderColor: !!borderCol ? borderCol :  theme.palette.text.primary,
		width: indicatorSize,
		height: indicatorSize

	})
)

const IndicatorLabel = styled(Typography, {
		shouldForwardProp: (prop: string) => prop !== 'disable'
	}
)<{ disable: boolean }>(
	({ disable, theme }) => ({
		fontFamily: 'DIN_2014',
		fontStyle: 'normal',
		fontWeight: 700,
		fontSize: '20px',
		lineHeight: '28px',
		color: disable ? theme.palette.text.primary : theme.palette.text.secondary
	})
)

const ProgressCardHeader: FC<ProgressCardHeader> = ({ step, disabled = false, done }) => {
	const { t } = useTranslation()
	const context = useContext(CurrentStepContext)

	const backgroundColor: string | null = useMemo(() => {
		if (done) {
			return '#D1FAE5'
		} else if (disabled) {
			return 'transparent'
		}
		return null
	}, [disabled, done, context.currentStep])

	const border: string = useMemo(() => {
		if (done) {
			return 'solid 1px'
		} else if (disabled) {
			return 'solid 2px'
		}
		return 'none'
	}, [disabled, done, context, context.currentStep])

	const borderColor: string | null = useMemo(() => {
		if (done) {
			return '#10B981'
		}
		return null
	}, [disabled, done, context, context.currentStep])

	return (
		<Stack flexDirection="row" alignItems="center" justifyContent="space-between" boxSizing="border-box" gap="12px" alignSelf="stretch">
			<Stack flexDirection="row" alignItems="center" gap="16px" alignSelf="stretch">
				<IndicatorContainer backgroundColor={backgroundColor} border={border} borderCol={borderColor}>
					{done ? (
						<CheckIcon color="success" />
					) : (
						<IndicatorLabel disable={disabled}>{step.rank}</IndicatorLabel>
					)}
				</IndicatorContainer>
				<LabelTitle padding="0">{t(step.title)}</LabelTitle>
			</Stack>
			{(!disabled || done) && (
				<RefreshButton rankStep={step.rank} />
			)}
		</Stack>
	)
}

export default ProgressCardHeader
