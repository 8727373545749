import React, { FunctionComponent, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { concatFittingInformationForRecap } from '../../../../../../../../utils/selectorUtils'
import { Stack, styled } from '@mui/material'
import LabelSubtitle from '../../../../../../../typo/LabelSubtitle'
import { SOURCE_VALUE } from '../../../../../../../../modules/selector/utils/constants'
import { LOCAL_STORAGE_KEYS } from '../../../../../../../../config/enums'
import { PricesContext } from "../../../../../../../../utils/contextes/PricesContext";
import { CustomStoreElementsContext } from "../../../../../../../../utils/contextes/CustomStoreElementsContext";

const Container = styled(Stack)({
	width: '35%',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-end',
	gap: '6px'
})

const Row = styled(Stack)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
	alignItems: 'center',
	gap: '12px'
})

const FittingItemRightSection: FunctionComponent<FittingItemType> = ({ source }) => {
	const { t } = useTranslation()

	let material = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_MATERIAL)!
	let standard = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STANDARD)!
	let standardTranslated = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STANDARD_TRANSLATION)!
	let gender = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_GENDER)!
	let genderTranslated = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_GENDER_TRANSLATION)!
	let shape = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SHAPE)!
	let shapeTranslated = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SHAPE_TRANSLATION)!
	let specificDescription = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SPECIFIC_DESCRIPTION)!
	let specificDescriptionTranslated = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SPECIFIC_DESCRIPTION_TRANSLATION)!
	let size = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SIZE)!

	if (source !== SOURCE_VALUE.FITTING_A) {
		material = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_MATERIAL)!
		standard = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STANDARD)!
		standardTranslated = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STANDARD_TRANSLATION)!
		gender = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_GENDER)!
		genderTranslated = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_GENDER_TRANSLATION)!
		shape = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SHAPE)!
		shapeTranslated = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SHAPE_TRANSLATION)!
		specificDescription = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_SPECIFIC_DESCRIPTION)!
		specificDescriptionTranslated = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SPECIFIC_DESCRIPTION_TRANSLATION)!
		size = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_SIZE)!
	}

	const { fittingAPrice, fittingBPrice } = useContext(PricesContext)
	const { isCustomStore } = useContext(CustomStoreElementsContext)

	return (
		<Container>
			<Row>
				<LabelSubtitle color="#828282">{t('components.productItem.fitting.material')}</LabelSubtitle>
				<LabelSubtitle>{t(`${material}`)}</LabelSubtitle>
			</Row>
			<Row>
				<LabelSubtitle color="#828282">{t('components.productItem.fitting.configuration')}</LabelSubtitle>
				<LabelSubtitle>
					{concatFittingInformationForRecap(standardTranslated, genderTranslated, shapeTranslated, specificDescriptionTranslated)}
				</LabelSubtitle>
			</Row>
			<Row>
				<LabelSubtitle color="#828282">{t('components.productItem.fitting.size')}</LabelSubtitle>
				<LabelSubtitle>{size}</LabelSubtitle>
			</Row>
			{
				isCustomStore && (
					source === SOURCE_VALUE.FITTING_A ? (
						!!fittingAPrice &&
						<Row>
							<LabelSubtitle color="#828282">{t('components.productItem.fitting.price')}</LabelSubtitle>
							<LabelSubtitle>{fittingAPrice} €</LabelSubtitle>
						</Row>
					) : (
						!!fittingBPrice &&
						<Row>
							<LabelSubtitle color="#828282">{t('components.productItem.fitting.price')}</LabelSubtitle>
							<LabelSubtitle>{fittingBPrice} €</LabelSubtitle>
						</Row>
					)
				)
			}
		</Container>
	)
}

export default FittingItemRightSection
