import React, {createContext, FC, useContext, useEffect, useState} from "react";
import {LOCAL_STORAGE_KEYS} from "../../config/enums";
import {getPartPrice} from "../../modules/selector/services/storePartApi";
import {CustomStoreElementsContext} from "./CustomStoreElementsContext";
import {TYPE_PRIX} from "../../modules/selector/utils/constants";

type PricesContext = {
    hosePrice: number | null,
    hosePricePerMeter: number | null,
    fittingAPrice: number | null,
    fittingBPrice: number | null,
}

type PricesContextProviderType = {
    children: any
}

export const PricesContext = createContext<PricesContext>({
    hosePrice: null,
    hosePricePerMeter: null,
    fittingAPrice: null,
    fittingBPrice: null,

})

export const PricesContextProvider: FC<PricesContextProviderType> = ({children}) => {

    const hoseStorePartNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER)
    const fittingAStorePartNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STORE_PART_NUMBER)
    const fittingBStorePartNumber =  localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STORE_PART_NUMBER)
    const quantite =  localStorage.getItem(LOCAL_STORAGE_KEYS.QUANTITY)
    const [ hosePrice, setHosePrice ] = useState<number>(0)
    const [ hosePricePerMeter, setHosePricePerMeter ] = useState<number>(0)
    const [ fittingAPrice, setFittingAPrice ] = useState<number>(0)
    const [ fittingBPrice, setFittingBPrice ] = useState<number>(0)
    const { typePrix, prixFixe, tauxHoraire, dureesAssemblage } = useContext(CustomStoreElementsContext)

    useEffect(() => {
        if (!!hoseStorePartNumber) {
            getPartPrice(hoseStorePartNumber)
                .then(
                    data => {
                        if (!isNaN(Number(data))){
                            let price = Number(data) * Number(localStorage.getItem(LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH)) / 1000
                            setHosePrice(price)
                            setHosePricePerMeter(Number(data))
                        }
                    }
                )
        }
        if (!!fittingAStorePartNumber) {
            getPartPrice(fittingAStorePartNumber)
                .then(
                    data => {
                        if (!isNaN(Number(data))){
                            setFittingAPrice(Number(data))
                        }
                    }
                )
        }
        if (!!fittingBStorePartNumber) {
            getPartPrice(fittingBStorePartNumber)
                .then(
                    data => {
                        if (!isNaN(Number(data))){
                            setFittingBPrice(Number(data))
                        }
                    }
                )
        }
    }, [])

    let servicePrice = hosePrice + fittingAPrice + fittingBPrice
    if (typePrix === TYPE_PRIX.FIXE && !!prixFixe) {
        servicePrice = servicePrice + (servicePrice * prixFixe/100)
    }
    if (typePrix === TYPE_PRIX.VARIABLE && !!tauxHoraire && !!dureesAssemblage && dureesAssemblage.length > 0) {
        let dureeAssemblage = 0
        const hoseInsideDiameter = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_DIAMETER_MM))
        if (hoseInsideDiameter <= 5) {
            dureeAssemblage = dureesAssemblage[0] ?? 0
        }
        else if (hoseInsideDiameter <= 6) {
            dureeAssemblage = dureesAssemblage[1] ?? 0
        }
        else if (hoseInsideDiameter <= 8) {
            dureeAssemblage = dureesAssemblage[2] ?? 0
        }
        else if (hoseInsideDiameter <= 10) {
            dureeAssemblage = dureesAssemblage[3] ?? 0
        }
        else if (hoseInsideDiameter <= 13) {
            dureeAssemblage = dureesAssemblage[4] ?? 0
        }
        else if (hoseInsideDiameter <= 16) {
            dureeAssemblage = dureesAssemblage[5] ?? 0
        }
        else if (hoseInsideDiameter <= 19) {
            dureeAssemblage = dureesAssemblage[6] ?? 0
        }
        else if (hoseInsideDiameter <= 25) {
            dureeAssemblage = dureesAssemblage[7] ?? 0
        }
        else if (hoseInsideDiameter <= 32) {
            dureeAssemblage = dureesAssemblage[8] ?? 0
        }
        else if (hoseInsideDiameter <= 38) {
            dureeAssemblage = dureesAssemblage[9] ?? 0
        }
        else if (hoseInsideDiameter <= 51) {
            dureeAssemblage = dureesAssemblage[10] ?? 0
        }
        else if (hoseInsideDiameter <= 64) {
            dureeAssemblage = dureesAssemblage[11] ?? 0
        }
        else {
            dureeAssemblage = dureesAssemblage[12] ?? 0
        }
        servicePrice = servicePrice + tauxHoraire * (dureeAssemblage / 60)
    }
    const totalPrice = servicePrice * Number(quantite)
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOTAL_PRICE, totalPrice.toFixed(2))

    return <PricesContext.Provider value={{
        hosePrice,
        hosePricePerMeter,
        fittingAPrice,
        fittingBPrice
    }}>
        {children}
    </PricesContext.Provider>
}