import React, {useContext, useEffect, useState} from "react";
import {CustomStoreElementsContext} from "../../../../../../../utils/contextes/CustomStoreElementsContext";
import LabelSubtitle from "../../../../../../typo/LabelSubtitle";
import {useTranslation} from "react-i18next";
import {Stack, styled} from "@mui/material";
import {LOCAL_STORAGE_KEYS} from "../../../../../../../config/enums";

const SpecificityContainer = styled(Stack)({
    width: '36%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    borderLeft: '2px solid rgba(0, 0, 0, 0.1)',
    gap: '6px'
})

const Row = styled(Stack)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: '12px'
})
const DeliveryItem = () => {

    const { t } = useTranslation()
    const { delaiStandard, delaiOnRequest } = useContext(CustomStoreElementsContext)
    const [ livraisonStandard, setLivraisonStandard ] = useState<boolean>(true)

    useEffect(() => {
        const hoseStorePartNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER)
        const fittingAStorePartNumber = localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_A_STORE_PART_NUMBER)
        const fittingBStorePartNumber =  localStorage.getItem(LOCAL_STORAGE_KEYS.FITTING_B_STORE_PART_NUMBER)
        if (!hoseStorePartNumber || !fittingAStorePartNumber || !fittingBStorePartNumber) {
            setLivraisonStandard(false)
        }
    }, []);

    return (
        <>
            <LabelSubtitle>{t('components.assemblyProgressionPanel.delivery.title')}</LabelSubtitle>
            <SpecificityContainer>
                <Row>
                    <LabelSubtitle>
                        {
                            livraisonStandard ? delaiStandard : delaiOnRequest
                        }
                    </LabelSubtitle>
                </Row>
            </SpecificityContainer>
        </>
    )
}

export default DeliveryItem