import React, {FC, useContext} from 'react'
import {Link, Stack, styled} from '@mui/material'
import {ImArrowRight2} from 'react-icons/im'
import phaa from '../../../assets/img/home/logo_phaa.png'
import {useTranslation} from 'react-i18next'
import {DistributorContext} from '../../../utils/contextes/DistributorContext'
import {clearLocalStorage} from "../../../utils/selectorUtils";
import {CustomStoreElementsContext} from "../../../utils/contextes/CustomStoreElementsContext";

const Container = styled(Link)(
	({ theme }) => ({
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: '8px',
		background: '#F9F9F9',
		border: `2px solid ${theme.palette.primary.main}`,
		boxShadow: '0px 20px 25px -5px rgba(147, 110, 0, 0.25), 0px 10px 10px -5px rgba(147, 110, 0, 0.1)',
		borderRadius: '12px',
		width: '68%',
		marginBottom: '44px',
		cursor: 'pointer'
	})
)

const Title = styled('h4')({
	fontFamily: 'Pragmatica',
	fontSize: '40px',
	lineHeight: '48px',
	fontWeight: 800,
	letterSpacing: '-0.02em',
	fontStyle: 'normal',
	padding: 0,
	margin: 0
})

const Subtitle = styled('h4')({
	fontFamily: 'Pragmatica',
	fontSize: '21px',
	letterSpacing: '-0.02em',
	fontWeight: 400,
	fontStyle: 'normal',
	padding: 0,
	margin: 0
})

const GearImg = styled(Stack)({
	position: 'absolute',
	backgroundImage: `url(${phaa})`,
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundAttachment: 'cover',
	backgroundSize: 'contain',
	width: '200px',
	height: '200px'
})

const IconContainer = styled(Stack)(
	({ theme }) => ({
		backgroundColor: theme.palette.primary.main,
		alignItems: 'center',
		justifyCenter: 'center',
		alignSelf: 'stretch',
		padding: '35px 48px',
		borderBottomRightRadius: '10px',
		borderTopRightRadius: '10px'
	})
)

const HomeSelectorAccess: FC = () => {
	const { t } = useTranslation()
	const { token } = useContext(DistributorContext)
	const { isCustomStore } = useContext(CustomStoreElementsContext)

	return (
		<Container underline="none" onClick={() => clearLocalStorage()} href={`/selector?token=${token}`}>
			<Stack marginLeft="48px" flexDirection="row" alignItems="center" alignSelf="stretch">
				<GearImg />
				<Stack marginLeft="200px" paddingLeft="24px" alignSelf="stretch" justifyContent="center">
					<Subtitle>
						{
							isCustomStore
								? t('screens.home.selectorAccess.subtitleCustom')
								: t('screens.home.selectorAccess.subtitle')
						}
					</Subtitle>
					<Title>
						{
							isCustomStore
							? t('screens.home.selectorAccess.titleCustom')
							: t('screens.home.selectorAccess.title')
						}
					</Title>
				</Stack>
			</Stack>
			<IconContainer>
				<ImArrowRight2 color="#F9F9F9" size={60} />
			</IconContainer>
		</Container>
	)
}

export default HomeSelectorAccess
