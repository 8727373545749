import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, styled } from '@mui/material'
import BasicError from '../../../../components/errors/BasicError'
import { extractMaxPressure, extractVacuumPressure, generateHoseFiltersForApi, useCurrentStepContext } from '../../utils/utils'
import { CONF_PRESSURE_SLIDER, STEP_SLIDER, TYPE_PRESSURE } from '../../utils/constants'
import { getHosesWithFilter } from '../../services/hosesApi'
import { LOCAL_STORAGE_KEYS } from '../../../../config/enums'
import Loader from '../../../../components/global/Loader'
import LabelTitle from '../../../../components/typo/LabelTitle'
import SectionColorized from '../SectionColorized'
import LabelSubtitle from '../../../../components/typo/LabelSubtitle'
import BasicRadioButton from '../../../../components/form/radio/basic/BasicRadioButton'
import SliderZone from '../../../../components/form/slider/SliderZone'
import SliderSingleValue from '../../../../components/form/slider/SliderSingleValue'
import ProductGridSelector from '../../../../components/form/grid/product/ProductGridSelector'
import { STATUS_PRODUCT } from '../../../../utils/constants'
import { SnackContext } from '../../../../utils/contextes/SnackContext'
import { getMinPressure, getMinPressureVacuum } from '../../../../utils/selectorUtils'
import { DistributorContext } from '../../../../utils/contextes/DistributorContext';

const PressureVacuumSelectionContainer = styled(Stack)({
	flexDirection: 'row',
	alignItems: 'center',
	padding: '0 10px',
	gap: '12px'
})

const MaxWorkingPressure = () => {
	const { t } = useTranslation()
	const { currentStep, updateCurrentStep } = useCurrentStepContext()

	const [choice, setChoice] = useState(t('screens.selector.hose.maxWorkingPressure.scale.pressure').toString())
	const [productSelected, setProductSelected] = useState<HoseType | null>(null)
	const [vaccumLvlSelected, setVacuumLevelSelected] = useState<string | undefined>()
	const [sliderPressureValue, setSliderPressureValue] = useState<number | number[] | undefined>(CONF_PRESSURE_SLIDER.DEFAULT_VALUE)
	const [products, setProducts] = useState<Array<HoseType>>([])
	const [productsDisplayed, setProductsDisplayed] = useState<Array<HoseType>>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [vacuumPressure, setVacuumPressure] = useState<Array<VacuumDataType>>([])
	const { setVisibilitySnackbar } = useContext(SnackContext)
	const { token } = useContext(DistributorContext)

	const maxValueSlider = useMemo(() => extractMaxPressure(products), [products])

	useEffect(() => {
		getHosesWithFilter(generateHoseFiltersForApi(token!!))
			.then((hoses: Array<HoseType>) => {
				setProducts(hoses)
				setProductsDisplayed(hoses)
				setVacuumPressure(extractVacuumPressure(hoses))
				setVacuumLevelSelected(getMinPressureVacuum(hoses).toString())
			})
			.catch(() => setVisibilitySnackbar(true))
			.finally(() => setIsLoading(false))
	}, [])

	useEffect(() => {
		if (productSelected) {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				lastSubStep: currentStep.lastSubStep,
				firstSubStep: currentStep.firstSubStep,
				values: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE,
						value: productSelected.max_working_pressure_bar
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_VACUUM_PRESSURE,
						value: productSelected.vacuum_pressure_bar
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MIN_WORKING_TEMPERATURE,
						value: productSelected.min_working_temperature_c
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_TEMPERATURE,
						value: productSelected.max_working_temperatures.filter(it => it.media_code === localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA)!)[0].max_working_temperature
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_PART_NUMBER,
						value: productSelected.part_number
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER,
						value: productSelected.store_part_number
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_IMAGE,
						value: productSelected.image
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_SERIES_NAME,
						value: productSelected.series_name
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STATUS,
						value: productSelected.store_part_number ? t(`${STATUS_PRODUCT.REGULAR}`) : t(`${STATUS_PRODUCT.ON_REQUEST}`)
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_COLOR,
						value: productSelected.color
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_ABRASION_RESISTANCE,
						value: productSelected.abrasion_resistance
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MIN_BENDING_RADIUS_MM,
						value: productSelected.min_bending_radius_mm
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_TYPE_PRESSURE_SELECTED,
						value:  TYPE_PRESSURE.PRESSURE
					}
				]
			})
		} else if (productsDisplayed && productsDisplayed.length > 0) {
			updateCurrentStep({
				rank: currentStep.rank,
				subStepKey: currentStep.subStepKey,
				lastSubStep: currentStep.lastSubStep,
				firstSubStep: currentStep.firstSubStep,
				values: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE,
						value: choice === t('screens.selector.hose.maxWorkingPressure.scale.pressure') ? sliderPressureValue : getMinPressure(productsDisplayed).toString()
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_VACUUM_PRESSURE,
						value: choice === t('screens.selector.hose.maxWorkingPressure.scale.pressure') ? getMinPressureVacuum(productsDisplayed).toString() : vaccumLvlSelected
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_TYPE_PRESSURE_SELECTED,
						value: choice === t('screens.selector.hose.maxWorkingPressure.scale.pressure') ? TYPE_PRESSURE.PRESSURE : TYPE_PRESSURE.VACUUM
					}
				]
			})
		}

		if (sliderPressureValue && choice === t('screens.selector.hose.maxWorkingPressure.scale.pressure')) {
			setProductsDisplayed(products.filter(product => Number(product.max_working_pressure_bar) >= Number(sliderPressureValue)))
		} else {
			setProductsDisplayed(products.filter(product => Number(product.vacuum_pressure_bar) >= Number(vaccumLvlSelected)))
		}
	}, [choice, vaccumLvlSelected, productSelected, sliderPressureValue, currentStep.subStepKey, productsDisplayed.length])

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<LabelTitle padding="24px 0">{t('screens.selector.hose.maxWorkingPressure.chooseTitle')}</LabelTitle>
					<SectionColorized>
						<LabelSubtitle>{t('screens.selector.hose.maxWorkingPressure.selectScale')}</LabelSubtitle>
						<PressureVacuumSelectionContainer>
							<BasicRadioButton
								label={t('screens.selector.hose.maxWorkingPressure.scale.pressure').toString()}
								itemSelected={choice}
								setSelection={setChoice}
							/>
							<BasicRadioButton
								label={t('screens.selector.hose.maxWorkingPressure.scale.vacuum').toString()}
								itemSelected={choice}
								setSelection={setChoice}
							/>
						</PressureVacuumSelectionContainer>
						{choice === t('screens.selector.hose.maxWorkingPressure.scale.pressure') ? (
							<SliderZone>
								<SliderSingleValue
									min={CONF_PRESSURE_SLIDER.MIN}
									max={maxValueSlider}
									step={STEP_SLIDER}
									defaultValue={CONF_PRESSURE_SLIDER.DEFAULT_VALUE}
									setSliderValue={setSliderPressureValue}
									leftLabel={t('global.unit.pressure.bar', { pressure: CONF_PRESSURE_SLIDER.MIN })}
									rightLabel={t('global.unit.pressure.bar', { pressure: maxValueSlider })}
								/>
							</SliderZone>
						) : (
							<div>
								{(vacuumPressure && vacuumPressure.length > 0) ? (
									<>
										<LabelSubtitle>{t('screens.selector.hose.maxWorkingPressure.selectVacuumLevel')}</LabelSubtitle>
										<Stack flexDirection="row" padding="10px 10px 0 10px">
											{vacuumPressure.map((lvl: VacuumDataType) => (
												<Stack key={lvl.vacuum_pressure} marginRight="12px">
													<BasicRadioButton padding="5px 20px 1px 11px" label={lvl.vacuum_pressure} itemSelected={vaccumLvlSelected} setSelection={setVacuumLevelSelected} />
												</Stack>
											))}
										</Stack>
									</>
								) : (
									<BasicError message={t('errors.noData.vacuum')} />
								)}
							</div>
						)}
					</SectionColorized>
					<LabelTitle padding="24px 0">{t('screens.selector.hose.maxWorkingPressure.matchingTitle')}</LabelTitle>
					{(productsDisplayed && productsDisplayed.length > 0) ? (
					<ProductGridSelector
						productSelected={productSelected}
						setProductSelected={setProductSelected}
						products={productsDisplayed}
					/>
					) : (
						<BasicError message={t('errors.noData.hoses')} />
					)}
				</>
			)}
		</>
	)
}

export default MaxWorkingPressure
