/**
 * Fichier regroupant les différentes enums
 */

// Énumération des clés des données stockées dans le local storage
export enum LOCAL_STORAGE_KEYS {
	HOSE_MEDIA =  'current_assembly.hose.media',
	HOSE_STANDARD = 'current_assembly.hose.standard',
	HOSE_TYPE = 'current_assembly.hose.type',
	HOSE_DIAMETER_MM = 'current_assembly.hose.inside_diameter_mm',
	HOSE_DIAMETER_INCH = 'current_assembly.hose.inside_diameter_inch',
	HOSE_DIAMETER_SIZE = 'current_assembly.hose.inside_diameter_size',
	HOSE_MAX_VACUUM_PRESSURE = 'current_assembly.hose.max_vacuum_pressure',
	HOSE_MAX_WORKING_PRESSURE = 'current_assembly.hose.max_working_pressure',
	HOSE_TYPE_PRESSURE_SELECTED = 'current_assembly.hose.pressure_type_selected',
	HOSE_MIN_WORKING_TEMPERATURE = 'current_assembly.hose.min_working_temperature',
	HOSE_MAX_WORKING_TEMPERATURE = 'current_assembly.hose.max_working_temperature',
	HOSE_PART_NUMBER = 'current_assembly.hose.part_number',
	HOSE_STORE_PART_NUMBER = 'current_assembly.hose.store_part_number',
	HOSE_SERIES_NAME = 'current_assembly.hose.series_name',
	HOSE_STATUS = 'current_assembly.hose.status',
	HOSE_IMAGE = 'current_assembly.hose.image',
	HOSE_MIN_BENDING_RADIUS_MM = 'current_assembly.hose.min_bending_radius_mm',
	HOSE_COLOR = 'current_assembly.hose.color',
	HOSE_ABRASION_RESISTANCE = 'current_assembly.hose.abrasion_resistance',
	FITTING_A_MATERIAL = 'current_assembly.fitting_a.material',
	FITTING_A_STANDARD = 'current_assembly.fitting_a.standard',
	FITTING_A_STANDARD_TRANSLATION = 'current_assembly.fitting_a.standard_translation',
	FITTING_A_GENDER = 'current_assembly.fitting_a.gender',
	FITTING_A_GENDER_TRANSLATION = 'current_assembly.fitting_a.gender_translation',
	FITTING_A_SHAPE = 'current_assembly.fitting_a.shape',
	FITTING_A_SHAPE_TRANSLATION = 'current_assembly.fitting_a.shape_translation',
	FITTING_A_SPECIFIC_DESCRIPTION = 'current_assembly.fitting_a.specific_description',
	FITTING_A_SPECIFIC_DESCRIPTION_TRANSLATION = 'current_assembly.fitting_a.specific_description_translation',
	FITTING_A_SIZE = 'current_assembly.fitting_a.size',
	FITTING_A_STATUS = 'current_assembly.fitting_a.status',
	FITTING_A_PART_NUMBER = 'current_assembly.fitting_a.part_number',
	FITTING_A_STORE_PART_NUMBER = 'current_assembly.fitting_a.store_part_number',
	FITTING_A_IMAGE = 'current_assembly.fitting_a.image',
	FITTING_A_SERIES_NAME = 'current_assembly.fitting_a.series_name',
	FITTING_A_CONNEXION_LENGTH_MM = 'current_assembly_fitting_a.connexion_length_mm',
	FITTING_A_MAX_WORKING_PRESSURE = 'current_assembly.fitting_a.max_working_pressure',
	FITTING_B_MATERIAL = 'current_assembly.fitting_b.material',
	FITTING_B_STANDARD = 'current_assembly.fitting_b.standard',
	FITTING_B_STANDARD_TRANSLATION = 'current_assembly.fitting_b.standard_translation',
	FITTING_B_GENDER = 'current_assembly.fitting_b.gender',
	FITTING_B_GENDER_TRANSLATION = 'current_assembly.fitting_b.gender_translation',
	FITTING_B_SHAPE = 'current_assembly.fitting_b.shape',
	FITTING_B_SHAPE_TRANSLATION = 'current_assembly.fitting_b.shape_translation',
	FITTING_B_SPECIFIC_DESCRIPTION = 'current_assembly.fitting_b.specific_description',
	FITTING_B_SPECIFIC_DESCRIPTION_TRANSLATION = 'current_assembly.fitting_b.specific_description_translation',
	FITTING_B_SIZE = 'current_assembly.fitting_b.size',
	FITTING_B_STATUS = 'current_assembly.fitting_b.status',
	FITTING_B_PART_NUMBER = 'current_assembly.fitting_b.part_number',
	FITTING_B_STORE_PART_NUMBER = 'current_assembly.fitting_b.store_part_number',
	FITTING_B_IMAGE = 'current_assembly.fitting_b.image',
	FITTING_B_SERIES_NAME = 'current_assembly.fitting_b.series_name',
	FITTING_B_CONNEXION_LENGTH_MM = 'current_assembly_fitting_b.connexion_length_mm',
	FITTING_B_MAX_WORKING_PRESSURE = 'current_assembly.fitting_b.max_working_pressure',
	ASSEMBLY_LENGTH = 'current_assembly.assembly.assembly_length_mm',
	FITTINGS_ANGLE = 'current_assembly.assembly.fittings_angle',
	QUANTITY = 'current_assembly.assembly.quantity',
	RECAP = 'current_assembly.assembly.recap',
	UNIT_PRICE = 'current_assembly.assembly.unit_price',
	SERVICE_PRICE = 'current_assembly.assembly.service_price',
	TOTAL_PRICE = 'current_assembly.assembly.total_price'
}