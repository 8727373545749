import React, { FC } from 'react'
import {Link, Stack, styled} from "@mui/material";
import { HeadsetMicRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type FloatingContactInfosButtonType = {
    mail: string | undefined | null,
    telephone: string | undefined | null,
    couleur: string | undefined | null,
}

const ContactInfos = styled(Stack)({
    flexDirection: 'row',
    position: 'fixed',
    top: '50%',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px',
    width: '32px',
    height: '50px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
    transition: 'width 1s',
    "&:hover": {
        width: "300px",
    },
})

const RowInput = styled(Stack)({
    flexDirection: 'row',
    width: '268px'
})
const FloatingContactInfosButton: FC<FloatingContactInfosButtonType> = (
    {
        mail,
        telephone,
        couleur
    }
) => {

    const { t } = useTranslation()


    return (
        <ContactInfos style={{backgroundColor : !!couleur ? couleur : '#FFB91D'}}>
            <HeadsetMicRounded fontSize={"large"} style={{marginRight: '15px'}}/>
            <Stack>
                {
                    mail &&
                    <RowInput>
                        <Link href={`mailto:${mail}`} underline="none" style={{color: "black"}}>
                            {t('screens.selector.hose.transportedFluid.mail') + mail}
                        </Link>
                    </RowInput>
                }
                {
                    telephone &&
                    <RowInput style={{marginTop: "4px"}}>
                        {t('screens.selector.hose.transportedFluid.tel') + telephone }
                    </RowInput>
                }
            </Stack>
        </ContactInfos>
    )
}

export default FloatingContactInfosButton