import { get, post } from './Request'

/**
 * Vérifie que le token du store est valide
 * @param body
 */
export const checkStore = (body: string) => post('/check_store', body)

/**
 * Récupère le logo custom du store s'il y en a un
 * @param token
 */
export const getCustomStoreElementsPictures = (token: string) => get<any>(`/store/${token}/pictures`)

/**
 * Récupère les informations custom du store s'il y en a
 * @param token
 */
export const getCustomStoreElements = (token: string) => get<any>(`/store/${token}/customStoreElements`)