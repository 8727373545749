import { styled } from '@mui/material'

const NavbarButton = styled('button')(
	({ theme }) => ({
		outline: 'inherit',
		border: 'none',
		padding: '10px 20px',
		backgroundColor: theme.palette.button.primary,
		borderRadius: '6px',
		gap: '12px',
		margin: '0 12px',
		cursor: 'pointer'
	})
)

export default NavbarButton
