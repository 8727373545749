import React, { useContext, useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import Navbar from './components/navigation/navbar/Navbar'
import NotFound from './modules/notFound/NotFound'
import { LOCAL_STORAGE_KEY } from './utils/constants'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from '@mui/material'
import { parkerFoTheme } from './config/theme'
import Home from './modules/home/Home'
import { CurrentStepContextProvider } from './utils/contextes/CurrentStepContext'
import Selector from './modules/selector/Selector'
import { SnackContextProvider } from './utils/contextes/SnackContext'
import { DistributorContext } from './utils/contextes/DistributorContext'
import { extractDistributorToken } from './utils/utils'
import { checkStore } from './utils/services/storesApi'
import Footer from './components/footer/Footer'
import { CustomStoreElementsContext } from "./utils/contextes/CustomStoreElementsContext";
import Loader from "./components/global/Loader";

function App() {
	const { i18n } = useTranslation()
	const { token, updateToken, setStoreInformation } = useContext(DistributorContext)
	const navigate = useNavigate()
	const location = useLocation()
	const {jauneParker, noirParker, blancParker, isLoading} = useContext(CustomStoreElementsContext)

	useEffect(() => {
		const selectedLanguage = localStorage.getItem(LOCAL_STORAGE_KEY.SELECTED_LANGUAGE)
		if (!selectedLanguage) {
			localStorage.setItem(LOCAL_STORAGE_KEY.SELECTED_LANGUAGE, i18n.language)
		} else {
			i18n.changeLanguage(selectedLanguage)
		}

		let distributorToken = extractDistributorToken()
		if (distributorToken === null){
			if (token === null){
				distributorToken = 'default'
			} else {
				distributorToken = token
				updateToken(distributorToken)
			}
		}
		console.log(distributorToken, token)
		if (distributorToken && distributorToken !== 'null' && distributorToken !== token ) {
			// Vérifie que le distributor token est valide
			checkStore(JSON.stringify({ token: distributorToken }))
				.then((data: StoreType) => {
					if (data && data.uuid) {
						setStoreInformation(data)
						updateToken(distributorToken)
					} else {
						navigate('/404')
					}
				})
				.catch(() => navigate('/404'))
		}

		if (!token && !distributorToken) {
			if (window.location.pathname === '/') {
				window.location.href='/complete/saml'
			} else {
				navigate('/404')
			}
		}

		if (distributorToken === 'null' || token === 'null') {
			navigate('/404')
		}

	}, [location])

	return (
		<>
			{
				isLoading
					? <Loader />
					: <ThemeProvider theme={parkerFoTheme(jauneParker, noirParker, blancParker)}>
					<Navbar />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/selector" element={
							<SnackContextProvider>
								<CurrentStepContextProvider>
									<Selector />
								</CurrentStepContextProvider>
							</SnackContextProvider>
						} />
						<Route path="*" element={<NotFound />} />
					</Routes>
					<Footer />
				</ThemeProvider>
			}
		</>
	)
}

export default App
