import React, {FC, useContext, useEffect, useMemo, useState} from 'react'
import { AiOutlineCheck, AiOutlineInfoCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import HoseSpecificityRow from './HoseSpecificityRow'
import { Link, Stack, styled, Tooltip } from '@mui/material'
import { LOCAL_STORAGE_KEYS } from '../../../../../../../config/enums'
import { NUMBER_FORMAT } from '../../../../../../../utils/constants'
import { CustomStoreElementsContext } from "../../../../../../../utils/contextes/CustomStoreElementsContext";
import {getPartPrice} from "../../../../../services/storePartApi";
import Loader from "../../../../../../../components/global/Loader";
import {PricesContext} from "../../../../../../../utils/contextes/PricesContext";

const HoseItemRightSectionContainer = styled(Stack)({
	width: '35%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-end',
	padding: 0,
	gap: '3px'
})

const HoseItemRightSection: FC<HoseItemSectionType> = ({ hose, isSelected, price }) => {
	const { t } = useTranslation()
	const { blancParker, noirParker } = useContext(CustomStoreElementsContext)
	const { hosePrice, hosePricePerMeter } = useContext(PricesContext)


	const gapValue = (currentValue: number, initialValue: number): number => {
		return Math.abs(Math.round((currentValue / initialValue) * 100 - 100))
	}

	const pressureIcon = useMemo(() => {
		if (Number(hose.max_working_pressure_bar) < Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE)) * 1.2) {
			return <AiOutlineCheck color="#10B981" />
		} else {
			return (
				<Tooltip title={t('screens.selector.hose.hoseSelection.tooltip', {
					gap: gapValue(Number(hose.max_working_pressure_bar), Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE))).toLocaleString(NUMBER_FORMAT)
				})}>
					<Link underline="none">
						<AiOutlineInfoCircle color="#FFB91D" />
					</Link>
				</Tooltip>
			)
		}
	}, [hose])

	const minWorkTempIcon = useMemo(() => {
		if (Number(hose.min_working_temperature_c) >= Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MIN_WORKING_TEMPERATURE)) * 1.2) {
			return <AiOutlineCheck color="#10B981" />
		} else {
			return (
				<Tooltip title={t('screens.selector.hose.hoseSelection.tooltip', {
					gap: gapValue(Number(hose.min_working_temperature_c), Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MIN_WORKING_TEMPERATURE))).toLocaleString(NUMBER_FORMAT)
				})}>
					<Link underline="none">
						<AiOutlineInfoCircle color="#FFB91D" />
					</Link>
				</Tooltip>
			)
		}
	}, [hose])

	const maxWorkTempIcon = useMemo(() => {
		const hoseMaxTemp = Number(hose.max_working_temperatures.filter(it => it.media_code === localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA))[0].max_working_temperature)
		const localStorageMaxTemp = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_TEMPERATURE))
		if (hoseMaxTemp >= localStorageMaxTemp && hoseMaxTemp <= (localStorageMaxTemp * 1.2)) {
			return <AiOutlineCheck color="#10B981" />
		} else {
			return (
				<Tooltip title={t('screens.selector.hose.hoseSelection.tooltip', {
					gap: gapValue(hoseMaxTemp, localStorageMaxTemp).toLocaleString(NUMBER_FORMAT)
				})}>
					<Link underline="none">
						<AiOutlineInfoCircle color="#FFB91D" />
					</Link>
				</Tooltip>
			)
		}
	}, [hose])
	
	const getMaxWorkingTemperature = (hose: HoseType): string => {
		const hoseMaxTemp = Number(hose.max_working_temperatures.filter(it => it.media_code === localStorage.getItem(LOCAL_STORAGE_KEYS.HOSE_MEDIA))[0].max_working_temperature)
	    return hoseMaxTemp.toLocaleString(NUMBER_FORMAT)
	}

	console.log(price)
	console.log(hosePricePerMeter)

	return (
		<HoseItemRightSectionContainer>
			<HoseSpecificityRow
				isSelected={isSelected}
				label={t('components.productItem.hose.maxWorkingPressure')}
				value={t('global.unit.pressure.bar', { pressure: hose.max_working_pressure_bar.toLocaleString(NUMBER_FORMAT) })}
				icon={pressureIcon}
			/>
			<HoseSpecificityRow
				isSelected={isSelected}
				label={t('components.productItem.hose.minWorkingTemperature')}
				value={t('global.unit.temperature.degC', { degrees: hose.min_working_temperature_c.toLocaleString(NUMBER_FORMAT) })}
				icon={minWorkTempIcon}
			/>
			<HoseSpecificityRow
				isSelected={isSelected}
				label={t('components.productItem.hose.maxWorkingTemperature')}
				value={t('global.unit.temperature.degC', { degrees: getMaxWorkingTemperature(hose) })}
				icon={maxWorkTempIcon}
			/>
			<HoseSpecificityRow
				isSelected={isSelected}
				label={t('components.productItem.hose.minBendingRadius')}
				value={t('global.unit.diameter.mm', { diameter: hose.min_bending_radius_mm.toLocaleString(NUMBER_FORMAT) })}
				icon={<AiOutlineCheck color={isSelected
					? !!noirParker ? noirParker : "#000"
					: !!blancParker ? blancParker : "#FFF"
				} />}
			/>
			{
				(!!hosePricePerMeter || !!price) &&
				<HoseSpecificityRow
					isSelected={isSelected}
					label={t('components.productItem.hose.price')}
					value={t('global.unit.price.euro', { price: !!hosePricePerMeter ? hosePricePerMeter : price } )}
					icon={<AiOutlineCheck color={isSelected
						? !!noirParker ? noirParker : "#000"
						: !!blancParker ? blancParker : "#FFF"
					} />}
				/>
			}

		</HoseItemRightSectionContainer>
	)
}

export default HoseItemRightSection
