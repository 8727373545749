import TransportedFluidContent from '../modules/selector/components/hose/TransportedFluidContent'
import React from 'react'
import InsideDiameter from '../modules/selector/components/hose/InsideDiameter'
import WorkingTemperature from '../modules/selector/components/hose/WorkingTemperature'
import AssemblyRecap from '../components/selector/content/assembly/recap/AssemblyRecap'
import { LOCAL_STORAGE_KEYS } from '../config/enums'
import MaxWorkingPressure from '../modules/selector/components/hose/MaxWorkingPressure'
import HoseSelection from '../modules/selector/components/hose/HoseSelection'
import FittingMaterial from '../modules/selector/components/fitting/FittingMaterial'
import FittingConfiguration from '../modules/selector/components/fitting/FittingConfiguration'
import FittingSize from '../modules/selector/components/fitting/FittingSize'
import DuplicateFitting from '../modules/selector/components/fitting/DuplicateFitting'
import AssemblyLength from '../modules/selector/components/assembly/AssemblyLength'
import FittingsAngle from '../modules/selector/components/assembly/FittingsAngle'
import Quantity from "../modules/selector/components/quantity/Quantity";

export const NUMBER_FORMAT = 'fr-FR'

export enum FORMAT_VALUE_LABEL_SLIDER {
	ANGLE = 'ANGLE',
	TEMPERATURE = 'TEMPERATURE'
}

// Code de statut des produits (hose et fitting)
export const STATUS_PRODUCT = {
	REGULAR: 'Regular product',
	ON_REQUEST: 'Product On request'
}

export const CHARACTER_LIMIT_ASSEMBLY_LENGTH = 6

export const FITTINGS_ANGLE_SLIDER = {
	min: 0,
	max: 360,
	step: 1,
	defaultValue: 90
}

/**
 * Nom des variables présentes dans le local storage
 */
export const LOCAL_STORAGE_KEY = {
	SELECTED_LANGUAGE: 'selected_language',
	CURRENT_ASSEMBLY: 'current_assembly',
	STORE_TOKEN: 'store_token'
}

export const INITIAL_STEP = {
	rank: 1,
	subStepKey: 'transportedFluid',
	lastSubStep: false,
	firstSubStep: true,
	values: undefined
}

export const DUPLICATE_FITTING_OPTION = [
	{
		key: 'duplicate',
		label: 'screens.selector.fitting.preConfiguration.duplicate'
	},
	{
		key: 'configure',
		label: 'screens.selector.fitting.preConfiguration.configure'
	}
]

/**
 * Liste des étapes du module "Your assembly"
 */
export const STEP_ASSEMBLY = [
	{
		rank: 1,
		title: 'components.assemblyProgressionPanel.hose.title',
		substeps: [
			{
				key: 'transportedFluid',
				title: 'components.assemblyProgressionPanel.hose.transportedFluid',
				leftMenu: true,
				view: <TransportedFluidContent />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MEDIA,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STANDARD,
						optional: true
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_TYPE,
						optional: true
					}
				]
			},
			{
				key: 'indiseDiameter',
				title: 'components.assemblyProgressionPanel.hose.indiseDiameter',
				leftMenu: true,
				view: <InsideDiameter />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_DIAMETER_MM,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_DIAMETER_INCH,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_DIAMETER_SIZE,
						optional: true
					}
				]
			},
			{
				key: 'maxWorkingPressure',
				title: 'components.assemblyProgressionPanel.hose.maxWorkingPressure',
				leftMenu: true,
				view: <MaxWorkingPressure />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_VACUUM_PRESSURE,
						optional: true
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_PRESSURE,
						optional: false
					}
				]
			},
			{
				key: 'workingTemperature',
				title: 'components.assemblyProgressionPanel.hose.workingTemperature',
				leftMenu: true,
				view: <WorkingTemperature />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MIN_WORKING_TEMPERATURE,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_MAX_WORKING_TEMPERATURE,
						optional: false
					}
				]
			},
			{
				key: 'hoseSelection',
				title: 'components.assemblyProgressionPanel.hose.hoseSelection',
				leftMenu: true,
				view: <HoseSelection />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_PART_NUMBER,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STORE_PART_NUMBER,
						optional: true
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_SERIES_NAME,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_STATUS,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.HOSE_IMAGE,
						optional: false
					}
				]
			}
		]
	},
	{
		rank: 2,
		title: 'components.assemblyProgressionPanel.fitting.titleA',
		substeps: [
			{
				key: 'fAMaterial',
				title: 'components.assemblyProgressionPanel.fitting.material',
				leftMenu: true,
				view: <FittingMaterial />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_A_MATERIAL,
						optional: false
					}
				]
			},
			{
				key: 'fAConfiguration',
				title: 'components.assemblyProgressionPanel.fitting.configuration',
				leftMenu: true,
				view: <FittingConfiguration />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_A_STANDARD,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_A_GENDER,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_A_SHAPE,
						optional: true
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_A_SPECIFIC_DESCRIPTION,
						optional: true,
					}
				]
			},
			{
				key: 'fASize',
				title: 'components.assemblyProgressionPanel.fitting.size',
				leftMenu: true,
				view: <FittingSize />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_A_SIZE,
						optional: false
					}
				]
			}
		]
	},
	{
		rank: 3,
		title: 'components.assemblyProgressionPanel.fitting.titleB',
		substeps: [
			{
				key: 'fBDuplicate',
				title: '',
				leftMenu: false,
				view: <DuplicateFitting />,
				data: []
			},
			{
				key: 'fBMaterial',
				title: 'components.assemblyProgressionPanel.fitting.material',
				leftMenu: true,
				view: <FittingMaterial />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_MATERIAL,
						optional: false
					}
				]
			},
			{
				key: 'fBConfiguration',
				title: 'components.assemblyProgressionPanel.fitting.configuration',
				leftMenu: true,
				view: <FittingConfiguration />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_STANDARD,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_GENDER,
						optional: false
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_SHAPE,
						optional: true
					},
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_SPECIFIC_DESCRIPTION,
						optional: true
					}
				]
			},
			{
				key: 'fBSize',
				title: 'components.assemblyProgressionPanel.fitting.size',
				leftMenu: true,
				view: <FittingSize />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTING_B_SIZE,
						optional: false
					}
				]
			}
		]
	},
	{
		rank: 4,
		title: 'components.assemblyProgressionPanel.assembly.title',
		substeps: [
			{
				key: 'assemblyLength',
				title: 'components.assemblyProgressionPanel.assembly.assemblyLength',
				leftMenu: true,
				view: <AssemblyLength />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.ASSEMBLY_LENGTH,
						optional: false
					}
				]
			},
			{
				key: 'fittingsAngle',
				title: 'components.assemblyProgressionPanel.assembly.fittingsAngle',
				leftMenu: true,
				view: <FittingsAngle />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.FITTINGS_ANGLE,
						optional: false
					}
				]
			},
			{
				key: 'quantity',
				title: 'components.assemblyProgressionPanel.quantity.libelle',
				leftMenu: false,
				view: <Quantity />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.QUANTITY,
						optional: false
					}
				]
			},
			{
				key:'recap',
				title: 'components.assemblyProgressionPanel.assembly.recap',
				leftMenu: false,
				view: <AssemblyRecap />,
				data: [
					{
						localStorageKey: LOCAL_STORAGE_KEYS.RECAP,
						optional: false
					}
				]
			}
		]
	},
]

export const DEFAULT_SLIDER_VALUE_WORKING_TEMPERATURE = {
	min: 0,
	max: 20
}
