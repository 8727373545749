import React from 'react'
import ReactDOM from 'react-dom/client'
import './config/i18n'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import { DistributorContextProvider } from './utils/contextes/DistributorContext'
import { CustomStoreElementsContextProvider } from "./utils/contextes/CustomStoreElementsContext";

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<DistributorContextProvider>
				<CustomStoreElementsContextProvider>
					<App />
				</CustomStoreElementsContextProvider>
			</DistributorContextProvider>
		</BrowserRouter>
	</React.StrictMode>
)
